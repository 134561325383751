<template>
  <v-dialog v-model="dialog" max-width="800px" origin="center center" persistent :fullscreen="isMobile">
    <v-card>
      <v-card-title>{{ isUpdating ? 'Editar' : 'Cadastrar' }} Fornecedor</v-card-title>
      <v-divider></v-divider>
      <v-card-title>
        <v-icon class="mr-1">
          {{ icons.mdiAccount }}
        </v-icon>
        <small>Dados de Fornecedor</small>
      </v-card-title>
      <div v-if="loading" class="d-flex justify-center">
        <v-progress-circular indeterminate color="secondary" class="ma-2"/>
      </div>
      <v-form v-show="!loading" v-model="formValid" ref="form">
        <v-card-text>
          <div class="row col-xs">
            <div class="col-md">
              <v-text-field
                v-model="supplierForm.name"
                :rules="[rules.required]"
                label="Nome"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </div>
            <div class="col-md">
              <v-text-field
                v-model="supplierForm.alias"
                :rules="[rules.required]"
                label="Apelido"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </div>
          </div>
          <div class="row col-xs">
            <div class="col-md">
              <v-select
                v-model="typePessoa"
                :items="pessoaSelect"
                :rules="[rules.required]"
                label="Tipo de Pessoa"
                filled
                outlined
                dense
                hide-details="auto"
              ></v-select>
            </div>
            <div class="col-md">
              <v-text-field
                v-model="cadastroModel"
                :rules="[rules.required, typePessoa == 'Pessoa Física' ? rules.cpf : rules.cnpj]"
                v-mask="typePessoa == 'Pessoa Física' ? '###.###.###-##' : '##.###.###/####-##'"
                :label="typePessoa == 'Pessoa Física' ? 'CPF' : 'CNPJ'"
                hide-details="auto"
                outlined
                dense
                :disabled="!typePessoa"
              ></v-text-field>
            </div>
          </div>
          <div class="row col-xs">
            <div class="col-md">
              <v-text-field
                v-model="supplierForm.inscricao_municipal"
                :rules="[rules.required]"
                label="Inscrição Municipal"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </div>
            <div class="col-md">
              <v-checkbox
                v-model="supplierForm.inscricao_estadual_isento"
                label="Isento de Inscrição Estadual"
                hide-details="auto"
                dense
              ></v-checkbox>
            </div>
            <div class="col-md">
              <v-text-field
                v-model="supplierForm.inscricao_estadual"
                :rules="[rules.required]"
                label="Inscrição Estadual"
                outlined
                dense
                hide-details="auto"
                v-if="supplierForm.inscricao_estadual_isento != 1"
              ></v-text-field>
            </div>
          </div>
          <div class="row col-xs">
            <div class="col-md">
              <v-text-field
                v-model="supplierForm.whatsapp"
                v-mask="'(##) # ####-####'"
                outlined
                label="WhatsApp"
                :rules="[rules.phoneNumber]"
                hide-details="auto"
                dense
              ></v-text-field>
            </div>
            <div class="col-md">
              <v-text-field
                v-model="supplierForm.phone"
                :rules="[rules.phoneNumber]"
                v-mask="'(##) # ####-####'"
                label="Telefone"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </div>
            <div class="col-md">
              <v-text-field
                v-model="supplierForm.email"
                :rules="[rules.email]"
                label="E-Mail"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </div>
          </div>
        </v-card-text>
      </v-form>
      <v-divider></v-divider>
      <v-card-title>
        <v-icon class="mr-1">
          {{ icons.mdiMapMarker }}
        </v-icon>
        <small>Endereço</small>
      </v-card-title>
      <div v-if="loading" class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          color="secondary"
        ></v-progress-circular>
      </div>
      <v-card-text>
        <AddressComponent v-if="!loading" ref="address" :initialData="supplierForm.main_address" @submit="handleAddress" :dense="true" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary " class="text-capitalize" @click="close">
          Cancelar
        </v-btn>
        <v-btn color="success" class="text-capitalize" @click="submitForm">
          <v-icon>{{ icons.mdiContentSave }}</v-icon>
          Salvar Informações
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AddressComponent from '@/components/app/address/addressComponent'
import { mdiAccount, mdiMapMarker, mdiContentSave } from '@mdi/js'
import formMixin from '@/util/mixins/formMixin'

export default {
  name: 'SupplierForm',

  components: { AddressComponent },

  mixins: [formMixin],

  data() {
    return {
      dialog: false,
      formValid: null,
      isUpdating: false,
      typePessoa: null,
      cadastroModel: null,
      loading: false,
      pessoaSelect: ['Pessoa Física', 'Pessoa Jurídica'],
      icons: {
        mdiAccount,
        mdiContentSave,
        mdiMapMarker,
      },
      supplierForm: {
        status: 1,
        id: null,
        name: null,
        avatar: null,
        alias: null,
        status: null,
        is_pj: null,
        is_pf: null,
        cnpj: null,
        cpf: null,
        inscricao_estadual: null,
        inscricao_estadual_isento: null,
        inscricao_municipal: null,
        whatsapp: null,
        phone: null,
        email: null,
        main_address: {
          type: "mainAddress",
          cep: null,
          number: null,
          street: null,
          neighborhood: null,
          complement: null,
          state: null,
          city: null,
        },
      },
    }
  },

  methods: {
    open(id) {
      this.dialog = true
      this.isUpdating = id != null
      this.cadastroModel = null

      if (id) {
        this.getSupplier(id);
      }
    },

    getSupplier(id){
      this.loading = true

      this.$http.$get(`/supplier/${id}`).then(res => {
        if (res.status == 200) {
          this.supplierForm = res.data
          this.typePessoa = res.data.is_pj == 1 ? 'Pessoa Jurídica' : 'Pessoa Física'
          this.cadastroModel = res.data.is_pf == 1 ? res.data.cpf : res.data.cnpj
          this.loading = false
        }
      });
    },

    handleAddress(e) {
      this.supplierForm.status = 1
      this.supplierForm.main_address = e
      this.supplierForm.main_address.type = "mainAddress"
    },

    close(){
      this.dialog = false
    },

    submitForm() {
      let formValid = this.$refs.form.validate()
      let addressFormValid = this.$refs.address.validate()
      
      if (formValid && addressFormValid) {
        if (this.isUpdating) {
          this.$http.$put(`/supplier/${this.supplierForm.id}`, this.supplierForm).then(res => {
            if (res.status == 200) {
              this.dialog = false
              this.$emit('onSubmit')
            }
          })
        } else {
          this.$http.$post('/supplier', this.supplierForm).then(res => {
            if (res.status == 200) {
              this.dialog = false
              this.$emit('onSubmit')
            }
          })
        }        
      }
    },
  },

  watch: {
    dialog(){
      if (!this.dialog) {
        this.$refs.address.resetForm()
        this.$refs.form.reset()        
      }
    },

    cadastroModel() {
      if (this.typePessoa == 'Pessoa Física') {
        this.supplierForm.cpf = this.cadastroModel
      } else {
        this.supplierForm.cnpj = this.cadastroModel
      }
    },

    typePessoa() {
      this.supplierForm.is_pf = this.typePessoa == 'Pessoa Física'
      this.supplierForm.is_pj = this.typePessoa != 'Pessoa Física'
    },
  },
}
</script>

<style>
</style>