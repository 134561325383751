<template>
  <div>
    <h1 class="ma-2 ml-0">
      Fornecedores
    </h1>
    <v-card v-if="suppliers.length">
      <v-card-title class="d-flex align-center justify-space-between py-sm-2 py-6">
        <div class="row">
          <v-text-field
            v-model="searchQuery"
            placeholder="Pesquisar"
            outlined hide-details dense
            class="user-search col-sm-6 pa-2"   
            v-debounce="applyFilter"
            debounce-events="input"
          />

          <v-select
            v-model="filterSelect"
            :items="filterSelectItems"
            item-text="text" item-value="value"
            outlined dense hide-details
            @change="applyFilter"
            class="col-sm-2 pa-2"
          />
        </div>
        <div :class="{'w-full py-2':isMobile}">
          <v-btn color="success" class="my-2 text-capitalize" :block="isMobile" @click="newSupplier()">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Novo Fornecedor</span>
          </v-btn>
        </div>
      </v-card-title>


      <v-data-table
        :items-per-page="25"
        :page.sync="page"
        :headers="tableColumns"
        :items="suppliersShow"
        :loading='loading'
        loading-text="Carregando Tabela..."
        @page-count="pageCount = $event"
        :footer-props="{
          itemsPerPageOptions: [25,50,100,-1],
          itemsPerPageText: 'Itens por pagina',
          itemsPerPageAllText: 'Todos',
          disablePagination: true,
          nextIcon: '',
          prevIcon: '',
          pageText: ''
        }"   
      >
        <template v-slot:item.name="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img v-if="item.avatar" :src="require(`@/assets/images/avatars/${item.avatar}`)"></v-img>
              <span v-else class="font-weight-medium">{{ avatarText(item.name) }}</span>
            </v-avatar>
            <div class="d-flex flex-column ms-3">
              <span>
                {{ item.name }}
              </span>
              <small>"{{ item.alias }}"</small>
            </div>
          </div>
        </template>

        <template v-slot:item.is_pj="{ item }">
          <div v-if="item.is_pj">
            <v-col>
              <small>CNPJ</small><br>
              <strong>{{ item.cnpj }}</strong>
            </v-col>
          </div>
          <div v-if="item.is_pf">
            <v-col>
              <small>CPF</small><br>
              <strong>{{ item.cpf }}</strong>
            </v-col>
          </div>
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip
            small
            :color="resolveUserStatusVariant(item.status)"
            :class="`${resolveUserStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            <div v-if="item.status === 1">Ativo</div>
            <div v-if="item.status === 0">Inativo</div>
          </v-chip>
        </template>


        <template v-slot:item.actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="editSupplier(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Editar</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item v-if="item.id == 1" @click="suspendSupplier(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Inativar</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-else @click="restoreSupplier(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiRestore }}
                  </v-icon>
                  <span>Reativar</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:no-data>
          <nothing-component></nothing-component>
        </template>

        <template v-slot:footer class="d-flex">
          <v-pagination
            v-model="page"
            :length="pageCount"
          ></v-pagination>
        </template>


      </v-data-table>
    </v-card>
    <nothing-component v-if="!suppliers.length && !loading">
        <div :class="{'w-full py-2':isMobile}">
          <v-btn color="success" class="my-2 text-capitalize" :block="isMobile" @click="newSupplier()">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Novo Fornecedor</span>
          </v-btn>
        </div>
    </nothing-component>
    <div v-if="loading" class="d-flex justify-center">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <supplier-form ref="supplierForm" @onSubmit="getSuppliers"/>
  </div>
</template>

<script>
import { mdiPlus, mdiExportVariant, mdiDotsVertical, mdiFileDocumentOutline, mdiDeleteOutline, mdiRestore } from '@mdi/js'
import SupplierForm from '@/components/suppliers/form/SupplierForm.vue'

import { getDirective } from 'vue-debounce'

import NothingComponent from '@/components/nothingComponent.vue'

import tableMixin from '@/util/mixins/tableMixin'

export default {
  name: 'Suppliers',

  components: { SupplierForm, NothingComponent },

  directives: {
    debounce: getDirective()
  },

  mixins: [tableMixin],

  data() {
    return {
      searchQuery: null,

      icons: {
        mdiPlus,
        mdiExportVariant,
        mdiDeleteOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiRestore
      },

      suppliers: [],
      loading: false,
      filterSelect: 'name',
      suppliersFiltered: [],
      page: null,
      pageCount: null,
      
      tableColumns: [
        { text: 'NOME', value: 'name' },
        { text: 'CADASTRO', value: 'is_pj' },
        { text: 'TELEFONE', value: 'phone' },
        { text: 'E-MAIL', value: 'email' },
        { text: 'STATUS', value: 'status' },
        { text: 'AÇÕES', value: 'actions' },
      ],

      filterSelectItems: [
        { text: 'Nome', value: 'name' },
        { text: 'CNPJ', value: 'cnpj' },
        { text: 'CPF', value: 'cpf' },
        { text: 'E-mail', value: 'email' },
      ],
    }
  },

  computed:{
    suppliersShow(){
      return this.searchQuery ? this.suppliersFiltered : this.suppliers
    },
  },

  mounted() {
    this.getSuppliers()
  },

  methods: {
    getSuppliers() {
      this.loading = true
      
      this.$http.$get('/supplier').then(res => {
        this.suppliers = res.data.data
        this.loading = false
      }).catch( err => console.log(err));
    },

    newSupplier() {
      this.$refs.supplierForm.open()
    },

    editSupplier(item) {
      this.$refs.supplierForm.open(item.id)
    },

    suspendSupplier(item) {
      this.$http.$put('/suplier', {id:item.id, status: 0}).then( res => {
        console.log(res);
        this.getSuppliers();
      }).catch(err => {
        console.log(err);
      })
    },
    
    restoreSupplier(item) {
      this.$http.$put('/suplier', {id:item.id, status: 1}).then( res => {
        console.log(res);
        this.getSuppliers();
      }).catch(err => {
        console.log(err);
      })
    },

    applyFilter(){
      if (this.searchQuery) {
        this.$http.index('/supplier', {fieldTable: this.filterSelect, q: this.searchQuery}).then(response => {
            this.suppliersFiltered = response.data.data
        }).catch( err => console.log(err));
      }
    }
  },
}
</script>